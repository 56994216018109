import { Component, OnInit } from "@angular/core";
import { PopoverRef } from "../popover/popover-ref";
import { Router } from "@angular/router";
import { StorageService } from "src/app/shared/services/storage.service";

@Component({
  selector: "vex-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  constructor(
    private readonly popoverRef: PopoverRef,
    private router: Router,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {}

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  async logout() {
    setTimeout(() => this.popoverRef.close(), 250);
    await this.storageService.remove("TOKEN_AUTH");
    await this.storageService.remove("userProfile");
    await this.router.navigate(["/login"]);
  }
}

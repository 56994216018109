import { BehaviorSubject, lastValueFrom, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import type { Usuario } from "../interfaces/usuario.interface";
import { currentUser } from "../interfaces/currentUser.interface";

@Injectable({
  providedIn: "root",
})
export class UsuariosService {
  private currentUserSubject: BehaviorSubject<Usuario> =
    new BehaviorSubject<Usuario>(null);
  public currentUser$ = this.currentUserSubject.asObservable();

  private userRoleSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public userRole$ = this.userRoleSubject.asObservable();

  baseUrl = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  async update(usuario: any) {
    return await lastValueFrom(
      this.http.put(this.baseUrl + `/users/update-user`, usuario)
    );
  }

  async delete(userId: number) {
    return await lastValueFrom(
      this.http.delete(this.baseUrl + `/users/delete-user/${userId}`)
    );
  }

  async getCount() {
    return await lastValueFrom(
      this.http.get<any>(this.baseUrl + `/users/get-count`)
    );
  }

  async getCountByEmpresaId() {
    return await lastValueFrom(
      this.http.get<any>(this.baseUrl + `/users/count-by-companyId`)
    );
  }

  async getPaginado(
    page?: number,
    size?: number,
    criteria?: string
  ): Promise<any> {
    let url =
      this.baseUrl +
      "/users/list-all-users-by-company?page=" +
      page +
      "&limit=" +
      size;

    if (criteria) {
      url = url + `&criteria=${criteria}`;
    }
    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async getPaginadoDesactived(
    page?: number,
    size?: number,
    criteria?: string
  ): Promise<any> {
    let url =
      this.baseUrl +
      "/users/list-all-users-by-company-desactived?page=" +
      page +
      "&limit=" +
      size;

    if (criteria) {
      url = url + `&criteria=${criteria}`;
    }
    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async getPaginadoAll(
    page?: number,
    size?: number,
    criteria?: string
  ): Promise<any> {
    let url =
      this.baseUrl + "/users/list-all-users?page=" + page + "&limit=" + size;

    if (criteria) {
      url = url + `&criteria=${criteria}`;
    }
    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async getPaginadoAllDesactivated(
    page?: number,
    size?: number,
    criteria?: string
  ): Promise<any> {
    let url =
      this.baseUrl +
      "/users/list-all-users-desactivated?page=" +
      page +
      "&limit=" +
      size;

    if (criteria) {
      url = url + `&criteria=${criteria}`;
    }
    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async getNameUsers(companyId: number) {
    return await lastValueFrom(
      this.http.get(
        this.baseUrl + `/users/list-all-name-users-by-company/${companyId}`
      )
    );
  }

  async approveUser(userId: number, approved: boolean) {
    return await lastValueFrom(
      this.http.put(this.baseUrl + `/users/approve-user/${userId}`, {
        approved,
      })
    );
  }
}

import { Injectable } from "@angular/core";
import { Breadcrumb } from "../interfaces/breadcrumb.interface";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  constructor() {}
  getBreadcrumbs(url: string): Breadcrumb[] {
    const arrayBreadCrumbs: Breadcrumb[] = new Array();

    const arraySplitRoute = url.split("/");
    if (!isNaN(Number(arraySplitRoute[arraySplitRoute.length - 1]))) {
      arraySplitRoute.splice(-1);
    }
    arraySplitRoute.forEach((r) => {
      if (r !== "") {
        switch (r) {
          case "analitico":
            arrayBreadCrumbs.push({ title: "Analítico", url: "analitico" });
            break;
          case "usuarios":
            arrayBreadCrumbs.push({ title: "Usuários", url: "usuarios" });
          case "empresas":
            arrayBreadCrumbs.push({ title: "Empresas", url: "empresas" });
            break;
          case "consultas":
            arrayBreadCrumbs.push({ title: "Consultas", url: "consultas" });
            break;
          case "credito":
            arrayBreadCrumbs.push({ title: "Credito", url: "credito" });
            break;

          case "lgpd":
            arrayBreadCrumbs.push({
              title: "Aviso LGPD",
              url: "lgpd",
            });
            break;

          case "configuracoes-conta":
            arrayBreadCrumbs.push({
              title: "Configurações de conta",
              url: "configuracoes-conta",
            });
            break;
        }
      }
    });
    return arrayBreadCrumbs;
  }
}

import { AuthService } from "src/app/shared/services/auth.service";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { NavigationService } from "../../services/navigation.service";
import { LayoutService } from "../../services/layout.service";
import { ConfigService } from "../../config/config.service";
import { map, startWith, switchMap } from "rxjs/operators";
import { NavigationLink } from "../../interfaces/navigation-item.interface";
import { PopoverService } from "../../components/popover/popover.service";
import { Observable, of } from "rxjs";
import { UserMenuComponent } from "../../components/user-menu/user-menu.component";
import { MatDialog } from "@angular/material/dialog";
import { SearchModalComponent } from "../../components/search-modal/search-modal.component";
import { StorageService } from "src/app/shared/services/storage.service";
import { UsuariosService } from "src/app/shared/services/usuarios.service";
import { Usuario } from "src/app/shared/interfaces/usuario.interface";
import {
  menuAdmin,
  menuAgent,
  menuMaster,
} from "src/app/shared/constantes/menu.enum";

@Component({
  selector: "vex-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(
    map((config) => config.sidenav.title)
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );
  showCollapsePin$ = this.configService.config$.pipe(
    map((config) => config.sidenav.showCollapsePin)
  );
  userVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.user.visible)
  );
  searchVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.search.visible)
  );

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;

  userName: string;
  userProfile: string;
  userPhoto: string;
  currentUser: Usuario;

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private storageService: StorageService,
    private readonly popoverService: PopoverService,
    private readonly dialog: MatDialog,
    private usuariosService: UsuariosService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    const roleUser: any = await this.authService.getRoleUser();

    this.userProfile = roleUser.role;

    if (
      this.userProfile === "ROLE_MASTER" ||
      this.userProfile === "ROLE_SUPER_ADMIN"
    ) {
      this.items = menuMaster;
    } else if (this.userProfile === "ROLE_ADMIN") {
      this.items = menuAdmin;
    } else {
      this.items = menuAgent;
    }

    this.userName = await this.storageService.get("userName");
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: "center",
            originY: "top",
            overlayX: "center",
            overlayY: "bottom",
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: "vex-dialog-glossy",
      width: "100%",
      maxWidth: "600px",
    });
  }
}

import { BehaviorSubject, lastValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { User } from "../interfaces/user.interface";
import { Usuario } from "../interfaces/usuario.interface";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    null
  );
  public currentUser$ = this.currentUserSubject.asObservable();

  private currentUserRoleSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public currentUserRole$ = this.currentUserRoleSubject.asObservable();

  async login(user: { email: string; password: string }) {
    const response: any = await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/login`, user)
    );
    if (response.success) {
      this.currentUserSubject.next(response.data.user);
    }

    return response;
  }

  async verifyToken(token: string) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/verify-token`, { token })
    );
  }

  async getPaginado(page?: number, size?: number): Promise<any> {
    let url =
      environment.BASE_URL +
      "/auth/get-all-request-user-by-company?page=" +
      page +
      "&pageSize=" +
      size;

    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async getPaginadoAll(page?: number, size?: number): Promise<any> {
    let url =
      environment.BASE_URL +
      "/auth/get-all-request-user?page=" +
      page +
      "&pageSize=" +
      size;

    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async create(usuario: Usuario) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/register`, usuario)
    );
  }

  async requestRegister(body: any) {
    return await lastValueFrom(
      this.http.post(environment.BASE_URL + `/auth/request-register`, body)
    );
  }

  async signup(body: { email: string; password: string }) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/signup`, body)
    );
  }

  async getRoleUser() {
    const response: any = await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/auth/get-role-user`)
    );
    this.currentUserRoleSubject.next(response.role);
    return response;
  }

  async getCompanyUser() {
    return await lastValueFrom(
      this.http.get(`${environment.BASE_URL}/auth/get-company-user`)
    );
  }

  async verifyAndSendTokenEmail(email: string) {
    return await lastValueFrom(
      this.http.post(
        `${environment.BASE_URL}/auth/verify-and-send-token-email`,
        { email }
      )
    );
  }

  async verifyTokenUser(email: string, token: string) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/verify-token-user`, {
        email,
        token,
      })
    );
  }

  async changePassword(body: {
    email: string;
    password: string;
    token: string;
  }) {
    return await lastValueFrom(
      this.http.post(`${environment.BASE_URL}/auth/change-password`, body)
    );
  }

  setAuthorizationToken(token: string) {
    localStorage.setItem("TOKEN_AUTH", token);
  }

  getAuthorizationToken(): string {
    return localStorage.getItem("TOKEN_AUTH");
  }

  clearAuthorizationToken() {
    localStorage.removeItem("TOKEN_AUTH");
  }
}

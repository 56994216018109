import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject } from "rxjs";
import { Notification } from "src/@vex/layout/toolbar/toolbar-notifications/interfaces/notification.interface";

@Injectable()
export class NotificationsService {
  notificationsSubject$ = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notificationsSubject$.asObservable();

  constructor(private socket: Socket) {}
}

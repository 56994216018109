import { Component, Input, OnInit } from "@angular/core";
import { trackByValue } from "../../utils/track-by";
import { Breadcrumb } from "src/app/shared/interfaces/breadcrumb.interface";

@Component({
  selector: "vex-breadcrumbs",
  template: `
    <div class="flex items-center">
      <vex-breadcrumb>
        <a [routerLink]="['/']">
          <mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>
        </a>
      </vex-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <vex-breadcrumb>
          <a *ngIf="crumb.url" [routerLink]="[crumb.url]">{{ crumb.title }}</a>
          <a *ngIf="!crumb.url">{{ crumb.title }}</a>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `,
})
export class BreadcrumbsComponent implements OnInit {
  @Input() crumbs: Breadcrumb[] = [];

  trackByValue = trackByValue;

  constructor() {}

  ngOnInit() {}
}

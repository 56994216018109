<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar w-full px-gutter flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="!mobileQuery"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block flex items-center"
  >
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg" />
    <h1
      [class.hidden]="!mobileQuery"
      class="title ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      VEX
    </h1>
  </a>

  <button
    *ngIf="isHorizontalLayout$ | async"
    [class.hidden]="mobileQuery"
    [matMenuTriggerFor]="addNewMenu"
    color="primary"
    mat-flat-button
    type="button"
  >
    Ações
  </button>

  <mat-menu
    #addNewMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below"
  >
    <button mat-menu-item (click)="goToPage('consultas')">
      <mat-icon svgIcon="mat:search"></mat-icon>
      <span>Realizar Consulta</span>
    </button>

    <button mat-menu-item (click)="goToPage('credito')">
      <mat-icon svgIcon="mat:attach_money"></mat-icon>
      <span>Comprar Créditos</span>
    </button>

    <!-- <div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div> -->
  </mat-menu>

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center"
  >
    <vex-navigation-item
      *ngFor="let item of navigationItems"
      [item]="item"
    ></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div
      class="flex items-center justify-center text-xs font-light mr-4 italic"
      *ngIf="syncing"
    >
      <mat-icon
        color="primary"
        svgIcon="mat:sync"
        class="mr-1 rotating"
      ></mat-icon>
      Sincronizando Contas
    </div>

    <div class="px-1 relative">
      <div
        *ngIf="unreadCount > 0"
        class="absolute bg-red-600 w-2 h-2 rounded-full top-1 right-2 flex items-center justify-center p-2 text-xs z-10 text-white font-semibold"
      >
        {{ unreadCount }}
      </div>
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>

    <div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div> -->

    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
  </div>
</div>

<vex-navigation
  *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"
></vex-navigation>

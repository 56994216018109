import { NavigationItem } from "src/@vex/interfaces/navigation-item.interface";

export const menuAgent: NavigationItem[] = [
  {
    type: "subheading",
    label: "Início",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Serviços",
    children: [
      {
        type: "link",
        label: "Consultar",
        route: "/consultas",
        icon: "mat:search",
      },

      {
        type: "link",
        label: "Consultas em Lote",
        route: "/consultas-lote",
        icon: "mat:folder",
      },
      {
        type: "link",
        label: "Consultas",
        route: "/consultas-tabela",
        icon: "mat:description",
      },
      {
        type: "dropdown",
        label: "Financeiro",
        icon: "mat:attach_money",
        children: [
          {
            type: "link",
            label: "Crédito",
            route: "/credito",
          },
        ],
      },
      {
        type: "link",
        label: "Extrato",
        route: "/extrato",
        icon: "mat:receipt",
      },
    ],
  },
  {
    type: "subheading",
    label: "Avisos",
    children: [
      {
        type: "link",
        label: "Aviso LGPD",
        route: "/lgpd",
        icon: "mat:security",
      },
    ],
  },
];

export const menuAdmin: NavigationItem[] = [
  {
    type: "subheading",
    label: "Início",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Administrativo",
    children: [
      {
        type: "dropdown",
        label: "Administrativo",
        icon: "mat:settings",
        children: [
          // {
          //   type: "link",
          //   label: "Empresas",
          //   route: "/empresas",
          // },
          {
            type: "link",
            label: "Configurações",
            route: "/configuracoes",
          },
          {
            type: "link",
            label: "Usuários",
            route: "/usuarios",
          },
          // {
          //   type: "link",
          //   label: "Tipos de Consultas",
          //   route: "/tipos-de-consultas",
          // },
          {
            type: "link",
            label: "Contas em Análise",
            route: "/contas-analise",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Serviços",
    children: [
      {
        type: "link",
        label: "Consultar",
        route: "/consultas",
        icon: "mat:search",
      },

      {
        type: "link",
        label: "Consultas em Lote",
        route: "/consultas-lote",
        icon: "mat:folder",
      },
      {
        type: "link",
        label: "Consultas",
        route: "/consultas-tabela",
        icon: "mat:description",
      },
      {
        type: "dropdown",
        label: "Financeiro",
        icon: "mat:attach_money",
        children: [
          {
            type: "link",
            label: "Crédito",
            route: "/credito",
          },
        ],
      },
      {
        type: "link",
        label: "Extrato",
        route: "/extrato",
        icon: "mat:receipt",
      },
    ],
  },
  {
    type: "subheading",
    label: "Avisos",
    children: [
      {
        type: "link",
        label: "Aviso LGPD",
        route: "/lgpd",
        icon: "mat:security",
      },
    ],
  },
];

export const menuMaster: NavigationItem[] = [
  {
    type: "subheading",
    label: "Início",
    children: [
      {
        type: "link",
        label: "Analítico",
        route: "/analitico",
        icon: "mat:insights",
        routerLinkActiveOptions: { exact: true },
      },
    ],
  },
  {
    type: "subheading",
    label: "Master Menu",
    children: [
      {
        type: "dropdown",
        label: "Master",
        icon: "mat:settings",
        children: [
          {
            type: "link",
            label: "Empresas",
            route: "/empresas",
          },
          {
            type: "link",
            label: "Usuários Ativos",
            route: "/todos-usuarios",
          },
          {
            type: "link",
            label: "Usuários Desativados",
            route: "/todos-usuarios-desativados",
          },
          {
            type: "link",
            label: "Tipos de Consultas",
            route: "/tipos-de-consultas",
          },
          {
            type: "link",
            label: "Registros em Análise",
            route: "/all-contas-analise",
          },
          {
            type: "link",
            label: "Relatório Geral",
            route: "/all-extracts",
          },
          {
            type: "link",
            label: "Avisos do Sistema",
            route: "/avisos",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Administrativo",
    children: [
      {
        type: "dropdown",
        label: "Administrativo",
        icon: "mat:settings",
        children: [
          // {
          //   type: "link",
          //   label: "Empresas",
          //   route: "/empresas",
          // },
          {
            type: "link",
            label: "Configurações",
            route: "/configuracoes",
          },
          {
            type: "link",
            label: "Usuários Ativos",
            route: "/usuarios",
          },
          {
            type: "link",
            label: "Usuários Desativados",
            route: "/usuarios-desativados",
          },
          // {
          //   type: "link",
          //   label: "Tipos de Consultas",
          //   route: "/tipos-de-consultas",
          // },
          {
            type: "link",
            label: "Contas em Análise",
            route: "/contas-analise",
          },
        ],
      },
    ],
  },
  {
    type: "subheading",
    label: "Serviços",
    children: [
      {
        type: "link",
        label: "Consultar",
        route: "/consultas",
        icon: "mat:search",
      },

      {
        type: "link",
        label: "Consultas em Lote",
        route: "/consultas-lote",
        icon: "mat:folder",
      },
      {
        type: "link",
        label: "Consultas",
        route: "/consultas-tabela",
        icon: "mat:description",
      },
      {
        type: "dropdown",
        label: "Financeiro",
        icon: "mat:attach_money",
        children: [
          {
            type: "link",
            label: "Crédito",
            route: "/credito",
          },
        ],
      },
      {
        type: "link",
        label: "Extrato",
        route: "/extrato",
        icon: "mat:receipt",
      },
    ],
  },
  {
    type: "subheading",
    label: "Avisos",
    children: [
      {
        type: "link",
        label: "Aviso LGPD",
        route: "/lgpd",
        icon: "mat:security",
      },
    ],
  },
];

export const menuManager = [];
export const menuMasterState = [];

import { Component, OnInit } from "@angular/core";
import { Notification } from "../interfaces/notification.interface";
import { DateTime } from "luxon";
import { trackById } from "../../../../utils/track-by";
import { Socket } from "ngx-socket-io";
import { of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { NotificationsService } from "src/app/shared/services/notifications.service";

@Component({
  selector: "vex-toolbar-notifications-dropdown",
  templateUrl: "./toolbar-notifications-dropdown.component.html",
  styleUrls: ["./toolbar-notifications-dropdown.component.scss"],
  animations: [fadeInUp400ms],
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  notifications: Notification[] = [
    // {
    //   id: "1",
    //   label: "Pagamento confirmado",
    //   icon: "mat:attach_money",
    //   colorClass: "text-green",
    //   datetime: DateTime.local().minus({ minutes: 30 }),
    //   read: true,
    // },
    // {
    //   id: "2",
    //   label: "New customer has registered",
    //   icon: "mat:account_circle",
    //   colorClass: "text-orange",
    //   datetime: DateTime.local().minus({ hour: 2 }),
    // },
    // {
    //   id: "3",
    //   label: "Campaign statistics are available",
    //   icon: "mat:insert_chart",
    //   colorClass: "text-purple",
    //   datetime: DateTime.local().minus({ hour: 5 }),
    // },
    // {
    //   id: "4",
    //   label: "Project has been approved",
    //   icon: "mat:check_circle",
    //   colorClass: "text-green",
    //   datetime: DateTime.local().minus({ hour: 9 }),
    // },
    // {
    //   id: "5",
    //   label: "Client reports are available",
    //   icon: "mat:description",
    //   colorClass: "text-primary",
    //   datetime: DateTime.local().minus({ hour: 30 }),
    // },
    // {
    //   id: "6",
    //   label: "New review received",
    //   icon: "mat:feedback",
    //   colorClass: "text-orange",
    //   datetime: DateTime.local().minus({ hour: 40 }),
    //   read: true,
    // },
    // {
    //   id: "7",
    //   label: "22 verified registrations",
    //   icon: "mat:verified_user",
    //   colorClass: "text-green",
    //   datetime: DateTime.local().minus({ hour: 60 }),
    // },
    // {
    //   id: "8",
    //   label: "New files available",
    //   icon: "mat:file_copy",
    //   colorClass: "text-amber",
    //   datetime: DateTime.local().minus({ hour: 90 }),
    // },
  ];
  notifications$ = of(this.notifications.reverse());
  trackById = trackById;

  constructor(
    private socket: Socket,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.socket.fromEvent("newNotification").subscribe((data: any) => {
      this.notifications.push({
        ...data.message,
        datetime: DateTime.local(),
      });
      this.notificationsService.notificationsSubject$.next(this.notifications);
    });
  }

  test() {
    this.socket.emit("getNotifications");
  }

  readAll() {
    this.notifications$.subscribe((items) => {
      items.forEach((item) => {
        item.read = true;
      });
    });
  }

  clearNotifications() {
    this.notifications$.subscribe((items) => {
      items.forEach((item) => {
        item.visible = false;
      });
    });
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable()
export class ClientesService {
  constructor(private http: HttpClient) {}

  async getClientByCompany(companyId: any) {
    return await lastValueFrom(
      this.http.get(
        environment.BASE_URL + `/clientes/cliente-by-empresaId/${companyId}`
      )
    );
  }

  async save(client) {
    return await lastValueFrom(
      this.http.post(environment.BASE_URL + `/clientes`, client)
    );
  }
}

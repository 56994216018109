import { lastValueFrom, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Empresa, EmpresaNames } from "../interfaces/empresa.interface";

@Injectable({
  providedIn: "root",
})
export class EmpresaService {
  baseUrl = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  async get() {
    return await lastValueFrom(
      this.http.get<Empresa[]>(
        environment.BASE_URL + `/companies/get-all-companies`
      )
    );
  }

  async getOne() {
    return await lastValueFrom(
      this.http.get<Empresa[]>(
        environment.BASE_URL + `/companies/get-one-company`
      )
    );
  }

  async approveCompany(id: number, approved: boolean) {
    return await lastValueFrom(
      this.http.put(environment.BASE_URL + `/companies/approve-company/${id}`, {
        approved,
      })
    );
  }

  async getNames() {
    return await lastValueFrom(
      this.http.get<EmpresaNames[]>(
        environment.BASE_URL + `/companies/get-all-companies-names`
      )
    );
  }

  async update(empresa: Empresa) {
    return await lastValueFrom(
      this.http.put(this.baseUrl + `/companies/update/${empresa.id}`, empresa)
    );
  }

  async post(empresa: Empresa) {
    return await lastValueFrom(
      this.http.post(this.baseUrl + `/companies/create`, empresa)
    );
  }

  async deleteEmpresa(id: number) {
    return await lastValueFrom(
      this.http.delete(this.baseUrl + `/empresas/${id}`)
    );
  }

  async deleteFinanceiro(id: number) {
    return await lastValueFrom(
      this.http.delete(this.baseUrl + `/financeiros/${id}`)
    );
  }

  async getCount() {
    return await lastValueFrom(
      this.http.get<any>(this.baseUrl + `/companies/get-count`)
    );
  }

  async getToken() {
    return await lastValueFrom(
      this.http.get<any>(this.baseUrl + `/companies/company-token`)
    );
  }

  async generateToken() {
    return await lastValueFrom(
      this.http.get<any>(this.baseUrl + `/companies/generate-new-token`)
    );
  }

  async getPaginado(
    page?: number,
    size?: number,
    criteria?: string
  ): Promise<any> {
    let url =
      this.baseUrl +
      "/companies/get-all-companies-paginado?page=" +
      page +
      "&pageSize=" +
      size;

    if (criteria) {
      url = url + `&criteria=${criteria}`;
    }
    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async filter() {}
}

import { Component, OnInit } from "@angular/core";
import { DateTime } from "luxon";

@Component({
  selector: "vex-quickpanel",
  templateUrl: "./quickpanel.component.html",
  styleUrls: ["./quickpanel.component.scss"],
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().setLocale('pt-BR').toFormat("DD");
  dayName = DateTime.local().setLocale('pt-BR').toFormat("EEEE");

  constructor() {}

  ngOnInit() {}
}

import { LoadingService } from "src/app/shared/services/loading.service";
import { AuthGuardService } from "./shared/services/auth-guard.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgxLoadingModule } from "ngx-loading";
import { ngxLoadingAnimationTypes } from "ngx-loading";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VexModule } from "../@vex/vex.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { HttpInterceptorAuth } from "./interceptor/http-interceptor";
import { AuthService } from "./shared/services/auth.service";
import { NgxMaskModule } from "ngx-mask";
import { ClientesService } from "./shared/services/clientes.service";

import { environment } from "src/environments/environment";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { NotificationsService } from "./shared/services/notifications.service";
import { NavigationService } from "./shared/services/navigation.service";
import { AvisosService } from "./shared/services/avisos.service";
import { EmpresaService } from "./shared/services/empresa.service";

registerLocaleData(localePt);
const config: SocketIoConfig = {
  url: environment.BASE_URL_SOCKET,
  options: { transports: ["websocket"] },
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
      fullScreenBackdrop: true,
    }),
    // Vex
    VexModule,
    CustomLayoutModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    StorageService,
    AuthGuardService,
    LoadingService,
    ClientesService,
    AuthService,
    AvisosService,
    EmpresaService,
    NavigationService,
    NotificationsService,
    AvisosService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorAuth, multi: true },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { Observable, catchError, throwError } from "rxjs";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpInterceptorAuth implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getAuthorizationToken();
    const systemToken = environment.TOKEN_CANDLE;

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          "x-api-key": systemToken,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          "x-api-key": systemToken,
        },
      });
    }
    return next.handle(req).pipe(
      catchError((err) => {
        if (err.status === 401 && this.router.url !== "/login") {
          this.snackbar.open(
            "Sessão expirada, faça login novamente.",
            undefined,
            {
              horizontalPosition: "end",
              verticalPosition: "top",
              panelClass: ["error-alert"],
              duration: 3000,
            }
          );
          this.authService.clearAuthorizationToken();
          this.router.navigate(["/login"]);
        }
        return throwError(() => err);
      })
    );
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    try {
      const userProfile = await this.authService.getRoleUser();
      const expectedRole = route.data.expectedRole;
      const profile = expectedRole.includes(userProfile.role);
      if ((await this.storageService.get("TOKEN_AUTH")) && profile) {
        return true;
      }
      await this.router.navigate(["error-unauthorized"]);
    } catch (error) {
      await this.router.navigate(["login"]);
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class NavigationService {
  private navigationStateSubject$ = new BehaviorSubject(null);
  navigationState$ = this.navigationStateSubject$.asObservable();

  setNavigationState(data: any) {
    this.navigationStateSubject$.next(data);
  }
}

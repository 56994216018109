import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable()
export class AvisosService {
  constructor(private http: HttpClient) {}

  async create(data: any) {
    return await lastValueFrom(
      this.http.post(environment.BASE_URL + `/avisos`, data)
    );
  }

  async get(page?: number, size?: number): Promise<any> {
    let url =
      environment.BASE_URL +
      "/avisos/find-all?page=" +
      page +
      "&pageSize=" +
      size;

    return await lastValueFrom(this.http.get<Array<any>>(url));
  }

  async update(data: any, id: any) {
    return await lastValueFrom(
      this.http.put(environment.BASE_URL + `/avisos/update/${id}`, data)
    );
  }

  async delete(id: any) {
    return await lastValueFrom(
      this.http.delete(environment.BASE_URL + `/avisos/delete/${id}`)
    );
  }

  async list() {
    return await lastValueFrom(
      this.http.get(environment.BASE_URL + `/avisos/list`)
    );
  }

  async enabledDisabled(id: any) {
    return await lastValueFrom(
      this.http.put(environment.BASE_URL + `/avisos/activate/${id}`, {})
    );
  }
}

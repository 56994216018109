<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p>Hoje</p>
  <div class="display-1 capitalize">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>PRÓXIMOS EVENTOS</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Reunião</h4>
    <p matLine>Em 16 minutos, Sala de Reunião</p>
  </a>
  <mat-divider></mat-divider>
  <h3 matSubheader>Lista de Tarefas</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Analisar Emprestimo Gabriel</h4>
    <p matLine>Adicionado: 6hrs</p>
  </a>
  <mat-divider></mat-divider>
</mat-nav-list>

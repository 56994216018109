import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ProgressBarModule } from "../components/progress-bar/progress-bar.module";
import { SearchModule } from "../components/search/search.module";
import { BreadcrumbsModule } from "../components/breadcrumbs/breadcrumbs.module";
import { SecondaryToolbarModule } from "../components/secondary-toolbar/secondary-toolbar.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    ProgressBarModule,
    SearchModule,
    BreadcrumbsModule,
    SecondaryToolbarModule,
    MatIconModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
